.containerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 70px;
  height: 80vh;
}

.background {
  position: absolute;
  height: 100%;
  width: 100%;
  background: url("../../assets/images/png/sad-astronaut-extra.png") no-repeat;
  background-size: 100%;
  top: 0;
  z-index: -1;
}

.content {
  position: relative;
  top: 250px;
}

.textCenter {
  text-align: center;
  font-family: ModernEra;
}

@media (max-width: 1050px) {
  .background {
    position: absolute;
    height: 100%;
    width: 100%;
    background: url("../../assets/images/png/sad-astronaut-big.png") no-repeat;
    background-size: 100%;
    top: 0;
    z-index: -1;
  }

  .content {
    position: relative;
    top: 150px;
  }
}

@media (min-width: 800px) {
  .title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    font-family: ModernEraBold;
  }

  .subTitle {
    font-size: 16px;
    text-align: center;
  }

  .noCandidateSubTitle {
    height: 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 799px) {
  .title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    width: 620px;
    font-family: ModernEraBold;
  }

  .subTitle {
    font-size: 16px;
  }

  .noCandidateSubTitle {
    height: 200px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .textCenter {
    text-align: center;
    padding: 0px 20px 0px 20px;
    width: 320px;
  }
}

@media (max-width: 600px) {
  .background {
    position: absolute;
    height: 100%;
    width: 100%;
    background: url("../../assets/images/png/sad-astronaut.png") no-repeat;
    background-size: 100%;
    top: 0;
    z-index: -1;
  }
}

@media (max-height: 700px) {
  .containerFlex {
    height: 500px;
  }
}

.hide {
  display: none;
}

.button {
  cursor: pointer;
}

.facebook {
  height: 100px;
}

.media {
  display: flex;
  justify-content: space-around;
  width: 200px;
}

.urlContainer {
  display: flex;
  justify-content: center;
}

@media (min-width: 700px) {
  .container {
    height: 200px;
    font-family: ModernEra;
  }

  .content {
    width: 430px;
    text-align: center;
    margin-bottom: 30px;
  }
}

@media (max-width: 699px) {
  .container {
    height: 200px;
    font-family: ModernEra;
  }

  .content {
    width: 300px;
    text-align: center;
    padding: 30px;
  }
  .media {
    display: flex;
    justify-content: space-between;
    width: 150px;
  }
}

.ContainerVisita {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Text {
  font-family: ModernEra;
  font-size: 16px;
  line-height: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.Space {
  margin-top: 100px;
}

@media (min-width: 800px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 15px;
    font-family: ModernEraBold;
  }
}

@media (max-width: 799px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 15px;
    font-family: ModernEraBold;
  }
}

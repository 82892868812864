.containerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 70px;
}

.heightContainer {
  min-height: 400px;
}

.textCenter {
  text-align: center;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 50px;
}

.yesNoButtons {
  width: 115px;
  height: 115px;
  border-radius: 17px;
  border: none;
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  cursor: pointer;
}

.yesNoButtons:focus {
  outline: none;
}

.fillButtom {
  border-radius: 21px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  border: solid 3px #0752f9;
  background-color: #ffffff;
  font-weight: bold;
  outline: none;
}

.buttonNext {
  width: 136px;
  height: 45px;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  width: 250px;
  justify-content: space-between;
}

@media (min-width: 800px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    font-family: ModernEraBold;
  }

  .subTitle {
    font-size: 24px;
  }

  .buttonSize {
    font-size: 24px;
  }

  .nextContainer {
    position: absolute;
    bottom: 20%;
  }
}

@media (max-width: 799px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    font-family: ModernEraBold;
  }

  .subTitle {
    font-size: 16px;
  }

  .titleContainer {
    width: 295px;
    text-align: center;
  }

  .buttonSize {
    font-size: 20px;
  }

  .nextContainer {
    position: absolute;
    bottom: 20%;
  }
}

@media (max-width: 450px) {
  .yesNoButtons {
    cursor: none;
  }

  .fillButtom {
    cursor: none;
  }
}

@media (min-height: 700px) and (max-width: 450px) {
  .nextContainer {
    position: absolute;
    bottom: 30%;
  }
}

@media (min-height: 850px) and (min-width: 500px) {
  .nextContainer {
    position: relative;
    bottom: -100px;
  }
}

@media (max-width: 350px) {
  .nextContainer {
    position: relative;
    top: 100px;
  }
}

.containerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 70px;
}

.heightContainer {
  min-height: 400px;
}

.textCenter {
  text-align: center;
}

.buttonNext {
  width: 136px;
  height: 45px;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
}

.hide {
  display: none !important;
}

@media (min-width: 800px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    font-family: ModernEraBold;
  }

  .subTitle {
    font-size: 16px;
    font-family: ModernEra;
  }

  .border {
    height: 60px;
    width: 460px;
    border-radius: 17px;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-family: ModernEra;
  }

  .borderSelected {
    height: 60px;
    width: 460px;
    border-radius: 21px;
    border: solid 3px #0752f9;
    background-color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    font-family: ModernEraBold;
  }

  .options div {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contentSize {
    width: 300px;
  }

  .leftIcon {
    background: #0752f9;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 9px;
    border-radius: 32px;
  }

  .nextContainer {
    padding-top: 30px;
  }
}

@media (max-width: 799px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    font-family: ModernEraBold;
  }

  .subTitle {
    font-size: 16px;
    font-family: ModernEra;
  }

  .border {
    width: 335px;
    height: 58px;
    border-radius: 17px;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: relative;
    font-size: 15px;
    font-family: ModernEra;
  }

  .borderSelected {
    width: 335px;
    height: 58px;
    border-radius: 21px;
    border: solid 3px #0752f9;
    background-color: #ffffff;
    font-weight: bold;
    position: relative;
    font-size: 15px;
    font-family: ModernEraBold;
  }

  .options div {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contentSize {
    width: 300px;
  }

  .leftIcon {
    background: #0752f9;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 9px;
    border-radius: 32px;
  }

  .nextContainer {
    position: relative;
    bottom: -20px;
  }
}

@media (max-width: 350px) {
  .border {
    width: 300px;
    height: 58px;
    border-radius: 17px;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: relative;
    font-size: 15px;
    font-family: ModernEra;
  }

  .borderSelected {
    width: 300px;
    height: 58px;
    border-radius: 21px;
    border: solid 3px #0752f9;
    background-color: #ffffff;
    font-weight: bold;
    position: relative;
    font-size: 15px;
    font-family: ModernEraBold;
  }
}

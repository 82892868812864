.FormContainer {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.Kit {
  padding-bottom: 50px;
}

.ImageContainer {
  max-width: 600px;
  width: 100%;
}

.Image {
  width: 100%;
}

.Title {
  font-family: ModernEra;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.Text {
  font-family: ModernEra;
  font-size: 16px;
  line-height: 20px;
}

.Space {
  margin-top: 100px;
}

@media (min-width: 800px) {
  .Form {
    max-width: 515px;
    width: 40%;
  }
}

@media (max-width: 799px) {
  .Form {
    max-width: 515px;
    width: 90%;
  }
}

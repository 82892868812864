.containerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 70px;
}

.textCenter {
  text-align: center;
}

.boder {
  width: 155px;
  height: 161px;
  border-radius: 17px;
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  position: relative;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin: 10px;
  outline: none;
}

.borderSelected {
  height: 161px;
  width: 155px;
  border-radius: 21px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  border: solid 3px #0752f9;
  background-color: #ffffff;
  position: relative;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  margin: 10px;
  outline: none;
}

.hide {
  display: none;
}

.buttonNext {
  width: 136px;
  height: 45px;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  color: white;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topIcon {
  background: #0752f9;
  position: relative;
  top: -78px;
  width: 20px;
  height: 20px;
  left: 43%;
  border-radius: 32px;
}

.imgDientes {
  width: 100%;
}

@media (min-width: 800px) {
  .heightContainer {
    min-height: 400px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    font-family: ModernEraBold;
  }

  .subTitle {
    font-size: 16px;
    font-family: ModernEra;
  }

  .imagesContaier {
    padding-top: 30px;
    width: 800px;
    display: flex;
    justify-content: space-around;
  }

  .nextContainer {
    position: absolute;
    bottom: 20%;
  }

  .imgContainer {
    position: absolute;
    top: 0;
  }

  .imagesContaier {
    max-width: 1000px;
  }
}

@media (max-width: 799px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    font-family: ModernEraBold;
  }

  .subTitle {
    font-size: 16px;
    font-family: ModernEra;
  }

  .imagesContaier {
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .imagesContaier div {
    margin: 15px 5px 15px 5px;
  }

  .nextContainer {
    position: absolute;
    bottom: 20%;
  }

  .imgContainer {
    position: absolute;
    top: 0;
  }
}

@media (max-width: 700px) {
  .nextContainer {
    position: relative;
    bottom: -20px;
  }
}

@media (max-width: 450px) {
  .boder {
    cursor: none;
  }

  .borderSelected {
    cursor: none;
  }
}

@media (max-height: 600px) {
  .nextContainer {
    position: initial;
    padding-top: 20px;
  }
}

@media (min-height: 850px) and (min-width: 500px) {
  .nextContainer {
    position: relative;
    bottom: -100px;
  }
}

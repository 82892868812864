.FooterContainer {
  position: fixed;
  bottom: 0;
}

@media (max-width: 500px) and (min-height: 801px) and (max-height: 900px) {
  .FooterContainer {
    margin-top: 100px;
  }
}

@media (max-width: 350px) {
  .WhatsAppContainer {
    display: none;
  }
}

@media (max-width: 500px) {
  .FooterContainer {
    position: relative;
  }
}

.Container {
  height: auto;
}

.Header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Logo {
  padding-left: 33px;
}

.Footer {
  padding: 20px;
  background-color: #f3f3f3;
  font-family: ModernEra;
  font-size: 13px;
  text-align: center;
  line-height: 20px;
}

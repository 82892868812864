.buttonNext {
  width: fit-content;
  padding-left: 30px;
  padding-right: 30px;
  height: 45px;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nextContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 799px) {
  .nextContainer {
    position: relative;
    bottom: -20px;
  }
}

@media (min-width: 800px) {
  .nextContainer {
    padding-top: 30px;
  }
}

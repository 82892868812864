.containerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 70px;
  height: 80vh;
}

.degraded {
  background: rgba(231, 253, 250, 1);
  background: -moz-linear-gradient(
    top,
    rgba(231, 253, 250, 1) 0%,
    rgba(255, 226, 246, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(231, 253, 250, 1)),
    color-stop(100%, rgba(255, 226, 246, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(231, 253, 250, 1) 0%,
    rgba(255, 226, 246, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(231, 253, 250, 1) 0%,
    rgba(255, 226, 246, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(231, 253, 250, 1) 0%,
    rgba(255, 226, 246, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(231, 253, 250, 1) 0%,
    rgba(255, 226, 246, 1) 100%
  );
}

.blue {
  color: blue;
}

.textCenter {
  text-align: center;
}

.emoji {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

@media (min-width: 800px) {
  .mainTitle {
    font-size: 48px;
    margin: 0;
  }

  .title {
    font-size: 28px;
    text-align: center;
    width: 620px;
    padding: 30px;
  }

  .subTitle {
    font-size: 24px;
    text-align: center;
  }

  .noCandidateSubTitle {
    height: 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .message {
    width: 700px;
    padding-bottom: 40px;
  }

  .img {
    width: auto;
    padding: 30px;
  }
}

@media (max-width: 799px) {
  .mainTitle {
    font-size: 34px;
    margin: 0;
    padding: 0 15px 0 15px;
    text-align: center;
  }

  .title {
    font-size: 24px;
    width: 308px;
    padding: 30px;
  }

  .subTitle {
    font-size: 16px;
  }

  .img {
    width: auto;
  }

  .noCandidateTitle {
    height: 400px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    text-align: center;
  }

  .noCandidateSubTitle {
    height: 200px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .message {
    width: 315px;
  }

  .textCenter {
    text-align: center;
    padding: 0px 20px 0px 20px;
  }
}

@media (max-height: 700px) {
  .containerFlex {
    height: 500px;
  }
}

@font-face {
  font-family: "ModernEra";
  src: url("./assets/fonts/ModerEra/ModernEraRegular.ttf");
}

@font-face {
  font-family: "ModernEraMono";
  src: url("./assets/fonts/ModerEra/ModernEraMonoRegular.ttf");
}

@font-face {
  font-family: "ModernEraMonoBold";
  src: url("./assets/fonts/ModerEra/ModernEraMonoBold.ttf");
}

@font-face {
  font-family: "ModernEraBold";
  src: url("./assets/fonts/ModerEra/ModernEraBold.ttf");
}

@font-face {
  font-family: "CooperBT";
  src: url("./assets/fonts/Cooper/CooperLtBT-Regular.ttf");
}

.inputContainer {
  margin-bottom: 1rem;
  width: 100%;
}

.onlyDesktop {
  display: none;
}

.errorMessage {
  color: #dc3545;
  font-style: italic;
}

.backButton {
  margin-top: 30px;
  text-decoration: underline;
  color: #666;
  cursor: pointer;
  display: inline-block;
  outline: none;
}

.inputContainer label.inputLabel {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

.inputContainer input,
.inputContainer select,
.installmentsCheckout select {
  width: 100%;
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-sizing: border-box;
  /* margin-bottom: 1rem; */
  /* margin-bottom: 10px; */
}

.actionButton {
  display: inline-block;
  text-decoration: none;
  width: 167px;
  padding: 12px 0;
  border-radius: 40px;
  background-color: #0752f8;
  color: #fff;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  outline: none;
}

.payButton:focus {
  outline: none;
}
.AllWidth {
  width: 100%;
}

.payButton {
  margin: 0 auto;
  margin-top: 20px;
  cursor: pointer;
  outline: none;
  border: none;
}
.payButtonBig {
  width: 250px;
}

.FormContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DisplayNone {
  display: none;
}

.ErrorMessage {
  color: red;
  font-size: 14px;
}

.Title {
  font-family: ModernEra;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

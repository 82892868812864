.containerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 70px;
}

.heightContainer {
  min-height: 400px;
}

.textCenter {
  text-align: center;
}

.buttonNext {
  width: 136px;
  height: 45px;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.container div {
  margin: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  border-bottom: 2px solid #8cc3fb;
}

input {
  font-size: 20px;
  border-bottom: 0px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

input:focus {
  outline: none;
}

.error {
  color: red;
  font-size: 14px;
}

@media (min-width: 800px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    font-family: ModernEraBold;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 20px;
  }

  .nextContainer {
    padding-top: 30px;
  }
}

@media (max-width: 799px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    font-family: ModernEraBold;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .nextContainer {
    position: relative;
    bottom: -20px;
  }

  label {
    font-size: 16px;
    margin: 10px 0px 10px 0px;
  }
}

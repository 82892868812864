.IframeClass {
  border: none;
  padding-bottom: 50px;
  height: 400px;
  width: 100%;
}

.ContainerMoldesKit {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Text {
  font-family: ModernEra;
  font-size: 16px;
  line-height: 20px;
}

.LeftText {
  text-align: left;
}

.Space {
  margin-top: 100px;
}

@media (max-width: 799px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 15px;
    font-family: ModernEraBold;
  }

  .ModelImg {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 90%;
  }

  .ImgMoldes {
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }
}

@media (min-width: 800px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    font-family: ModernEraBold;
  }

  .ModelImg {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 50%;
    display: flex;
    align-items: center;
  }

  .ImgMoldes {
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.OptionsCotnainer {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.contentSize {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Text {
  font-family: ModernEra;
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: 800px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 15px;
    font-family: ModernEraBold;
  }

  .body {
    padding-top: 30px;
  }

  .imgTop {
    width: 736px;
    object-fit: contain;
  }

  .border {
    height: 60px;
    width: 460px;
    border-radius: 17px;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-family: ModernEra;
  }

  .borderSelected {
    height: 60px;
    width: 460px;
    border-radius: 21px;
    border: solid 3px #0752f9;
    background-color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    font-family: ModernEraBold;
  }

  .leftIcon {
    background: #0752f9;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 17px;
    top: 19px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Text {
    width: 500px;
  }
}

@media (max-width: 799px) {
  .title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 15px;
    font-family: ModernEraBold;
  }

  .imgTop {
    position: relative;
    width: calc(100% + 30px);
    padding-bottom: 50px;
  }

  .border {
    width: 300px;
    height: 58px;
    border-radius: 17px;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: relative;
    font-size: 15px;
    font-family: ModernEra;
  }

  .borderSelected {
    width: 300px;
    height: 58px;
    border-radius: 21px;
    border: solid 3px #0752f9;
    background-color: #ffffff;
    font-weight: bold;
    position: relative;
    font-size: 15px;
    font-family: ModernEraBold;
  }

  .leftIcon {
    background: #0752f9;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 13px;
    top: 17px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-height: 700px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.hide {
  display: none;
}

.Space {
  margin-top: 100px;
}

.containerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 70px;
}

.heightContainer {
  min-height: 400px;
}

.textCenter {
  text-align: center;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 50px;
  font-family: ModernEra;
}

.inputContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.inp {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
  box-sizing: border-box;
}
.inp .label {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 16px;
  color: #9098a9;
  font-weight: 500;
  transform-origin: 0 0;
  transition: all 0.2s ease;
  box-sizing: border-box;
}
.inp .border {
  position: absolute;
  bottom: -16px;
  left: 0;
  height: 2px;
  width: 100%;
  background: #07f;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: all 0.15s ease;
  box-sizing: border-box;
}
.inp input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  padding: 12px 0;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #c8ccd4;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
  box-sizing: border-box;
}
.inp input:hover {
  background: rgba(34, 50, 84, 0.03);
  box-sizing: border-box;
}
.inp input:not(:placeholder-shown) + span {
  color: #5a667f;
  transform: translateY(-30px) scale(0.75);
  box-sizing: border-box;
}
.inp input:focus {
  background: none;
  outline: none;
  box-sizing: border-box;
}
.inp input:focus + span {
  color: #07f;
  transform: translateY(-30px) scale(0.75);
  box-sizing: border-box;
}
.inp input:focus + span + .border {
  transform: scaleX(1);
  box-sizing: border-box;
}

.error {
  color: red;
  font-size: 14px;
}

.buttonNext {
  width: 136px;
  height: 45px;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-family: ModernEraBold;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 800px) {
  .mainTitle {
    font-size: 30px;
    text-align: center;
    font-family: ModernEra;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    font-family: ModernEraBold;
    text-align: center;
  }

  .subTitle {
    font-size: 24px;
  }

  .form {
    width: 100%;
  }

  .buttonSize {
    font-size: 24px;
  }

  .nextContainer {
    position: relative;
    top: 100px;
  }
}

@media (max-width: 799px) {
  .mainTitle {
    font-size: 30px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    font-family: ModernEra;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    font-family: ModernEraBold;
    text-align: center;
  }

  .subTitle {
    font-size: 16px;
  }

  .titleContainer {
    width: 319px;
    text-align: center;
  }

  .form {
    width: 80%;
  }

  .buttonSize {
    font-size: 20px;
  }

  .nextContainer {
    position: relative;
    top: 50px;
  }
}

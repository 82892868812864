.Cotnainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.ImageContainer {
  max-width: 600px;
  min-width: 320px;
  width: 100%;
}

.Image {
  width: 100%;
  height: 100%;
}

.Title {
  padding-top: 30px;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 30px;
}

.Text {
  padding-top: 30px;
  font-family: ModernEra;
  font-size: 16px;
  text-align: center;
  padding: 30px;
}

.ButtonContainer {
  padding-top: 50px;
  padding-bottom: 50px;
}
